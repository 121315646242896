$(window).scroll(function(){
    topbarState();
});

$(document).ready(function() {
    topbarState();

    $('.menu-toggle').click(function() {
        $('.topbar').toggleClass('collapsed expanded');
    });



    $('.apparatuur-slides').bxSlider({
        mode: 'horizontal',
        auto: false,
        autoControls: false,
        pager: false,
        infiniteLoop: false,
        minSlides: 1,
        maxSlides: 4,
        slideMargin: 0,
        slideWidth: 724,
        controls: true,
        nextText: ' ',
        prevText: ' ',
        nextSelector: $('.content__apparatuur .slider-nav .next'),
        prevSelector: $('.content__apparatuur .slider-nav .prev'),
        hideControlOnEnd: true,
        preventDefaultSwipeY: false,
        preventDefaultSwipeX: false,
        oneToOneTouch: false,
        speed: 900,
        pause: 6000
    });

    $('.projecten-slides').bxSlider({
        mode: 'horizontal',
        auto: false,
        autoControls: false,
        stopAutoOnClick: true,
        pager: false,
        infiniteLoop: false,
        minSlides: 1,
        maxSlides: 4,
        slideWidth: 724,
        controls: true,
        nextText: ' ',
        prevText: ' ',
        nextSelector: $('.content__projecten .slider-nav .next'),
        prevSelector: $('.content__projecten .slider-nav .prev'),
        hideControlOnEnd: false,
        preventDefaultSwipeY: false,
        preventDefaultSwipeX: false,
        oneToOneTouch: false,
        speed: 900,
        pause: 6000
    });

});

function topbarState(){
    var scrolled=$(window).scrollTop();
    //console.log(scrolled);
    if(scrolled > 50) {
        $('.topbar').addClass('scrolled');
    }else {
        $('.topbar').removeClass('scrolled');
    }

    /*
    if($('.main-menu').hasClass('expanded') || scrolled > 30){
        $('.topbar').addClass('scrolled');
    } else {
        $('.topbar').removeClass('scrolled');
    }*/
}